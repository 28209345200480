/* eslint-disable @typescript-eslint/dot-notation,@typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserModel } from '../../entities/user.model';
import { StorageService } from '../../services/storage.service';
import { DataService } from '../../services/data.service';
import { Utils } from '../../../library/utils';
import { UserAccountService } from '../../services/user-account.service';
import { ViewHelperService } from '../../services/view-helper.service';

@Component({
    selector: 'app-modal-update-user-infos',
    templateUrl: './update-user-infos.modal.html',
    styleUrls: ['./update-user-infos.modal.scss'],
})

export class UpdateUserInfosModal implements OnInit {

    @Input() operationType: string;

    user = new UserModel({});

    phone: string;
    lastname: string;
    firstname: string;
    countryId: string;
    // city: string;
    address: string;
    job: string   = null;
    email: string = null;

    oldPassword: string;
    password: string;
    passwordConfirmation: string;

    // countries: Array<CountryModel> = [];
    canSubmit = false;

    errors = {};

    success: Record<string, any> = {};

    constructor(
        private modalController: ModalController,
        private storageService: StorageService,
        private viewHelperService: ViewHelperService,
        private dataService: DataService,
        private userAccountService: UserAccountService
    ) {
    }

    ngOnInit() {
        this.loadData();
    }

    close() {
        const data = {};
        this.modalController.dismiss(data).then();
    }

    // log() {
    //   console.log(this.user);
    //   console.log(this.lastname)
    // }

    submitUpdate() {
        if (this.validateInputs()) {
            const inputs = {
                lastname: this.lastname,
                firstname: this.firstname,
                country_id: this.countryId,
                // city: this.city,
                email: this.email,
                address: this.address,
                job: this.job
            };
            this.viewHelperService.showLoader('Requête en cours ... Veuillez patienter').then(() => {
                this.userAccountService.updateUserInfos(inputs).then(data => {
                    console.log(data);
                    this.viewHelperService.hideLoader();
                    this.userAccountService.saveRawUser(data.user).then(() => {
                        this.modalController.dismiss({ user: data.user }).then(() => {
                            this.viewHelperService.showToast('Informations personnelles mises à jour avec succès', 5000, 'Effectué !', 'success', 'OK').then();
                        });
                    });
                }).catch((error) => {
                    this.viewHelperService.hideLoader();
                    this.handleErrorFeedback(error);
                });
            });
        } else {
            this.viewHelperService.showErrorToast('Veuillez remplir correctement les champs', 3000).then();
        }
    }

    submitPasswordUpdate() {
        if (this.validatePasswordInputs()) {
            this.viewHelperService.showLoader('Requête en cours ... Veuillez patienter').then(() => {
                this.userAccountService.updateUserPassword(this.oldPassword, this.password).then(data => {
                    console.log(data);
                    this.viewHelperService.hideLoader();
                    this.modalController.dismiss().then(() => {
                        this.viewHelperService.showToast('Votre mot de passe a été bien modifiée', 5000, 'Effectué !', 'success', 'OK').then();
                    });
                    // this.dataService.storeUser(data.user).then(() => {
                    // });
                }).catch((error) => {
                    this.viewHelperService.hideLoader();
                    this.handleErrorFeedback(error);
                });
            });
        } else {
            this.viewHelperService.showErrorToast('Veuillez remplir correctement les champs', 3000).then();
        }
    }

    private handleErrorFeedback(error) {
        let errorInfos = '';
        if (error.status === 'fail') {
            for (const key of Object.keys(error.data)) {
                errorInfos += '<br><span class="text-color-danger">◆ ' + error.data[key][0] + '</span>';
            }
        } else {
            errorInfos = '<div class="text-color-danger ion-padding-top">' + error.message + '</div>';
        }
        this.viewHelperService.showAlert('Désolé', 'Votre requête n\'a pas pu être traitée : ' + errorInfos).then();
    }

    private loadData() {
        this.userAccountService.getUser().then(user => {
            // console.log(user);
            this.hydrateData(user);
        }).catch(() => {
            this.viewHelperService.showErrorToast('Veuillez vous connecter !', 0, 'Erreur').then();
        });

        // this.dataService.getCountries().then(countries => {
        //   console.log(countries);
        //   this.countries = countries;
        // });
    }

    private hydrateData(user: UserModel) {
        this.user = user;

        this.lastname  = user.lastname;
        this.firstname = user.firstname;
        this.phone     = user.phone;
        this.email     = user.email;
        this.countryId = '';
        // this.city      = user.city;
        this.address   = '';
        // this.job       = user.job;
    }

    private validateInputs() {
        this.canSubmit = true;
        this.errors    = {};

        // Phone
        this.phone = Utils.sanitizePhone(this.phone);
        if (this.phone.length < 8 || this.phone.length > 13) {
            this.canSubmit       = false;
            this.errors['phone'] = 'Veuillez saisir votre téléphone avec indicatif, au format international (ex: 22890909090)';
        }

        // Name
        if (Utils.isEmpty(this.lastname)) {
            this.canSubmit          = false;
            this.errors['lastname'] = 'Veuillez saisir un nom valide';
        }
        // Firstname
        if (Utils.isEmpty(this.firstname)) {
            this.canSubmit           = false;
            this.errors['firstname'] = 'Veuillez saisir un prénom valide';
        }
        // Country
        // console.log(this.countryId);
        // if (Utils.isEmpty(this.countryId)) {
        //   this.canSubmit            = false;
        //   this.errors['country_id'] = 'Veuillez sélectionner votre pays';
        // }
        // City
        // if (Utils.isEmpty(this.city)) {
        //   this.canSubmit      = false;
        //   this.errors['city'] = 'Veuillez saisir votre ville';
        // }

        // Email
        if (Utils.isEmpty(this.email)) {
            this.canSubmit       = false;
            this.errors['email'] = 'Veuillez saisir une addresse email';
        } else {
            this.email = this.email.toLowerCase();
            if (! Utils.isValidEmail(this.email)) {
                this.canSubmit       = false;
                this.errors['email'] = 'L\'addresse email n\'est pas valide';
            }
        }
        // Job
        // if (!Utils.isEmpty(this.job)) {
        //   if (this.job.length >= 100) {
        //     this.canSubmit     = false;
        //     this.errors['job'] = 'La valeur saisie trop longue';
        //   }
        // }

        // Address
        if (Utils.isEmpty(this.address)) {
            this.canSubmit         = false;
            this.errors['address'] = 'Veuillez saisir une adresse géographique';
        } else if (this.address.length >= 250) {
            this.canSubmit         = false;
            this.errors['address'] = 'L\'adresse saisie trop longue';
        }

        console.log('canSubmit', this.canSubmit);

        return this.canSubmit;
    }

    private validatePasswordInputs() {
        this.canSubmit = true;
        this.errors    = {};

        // Password
        if (this.oldPassword == null || this.password.length < 3) {
            this.canSubmit             = false;
            this.errors['oldPassword'] = 'Veuillez saisir le mot de passe actuel';
        }

        // Password
        if (this.password == null || this.password.length < 6) {
            this.canSubmit          = false;
            this.errors['password'] = 'Veuillez saisir un mot de passe d\'au moins 6 caractères';
        }
        // Password
        if (this.passwordConfirmation !== this.password) {
            this.canSubmit                      = false;
            this.errors['passwordConfirmation'] = 'Les mots de passe ne correspondent pas. Veuillez ressaisir';
        }

        console.log('canSubmit', this.canSubmit);

        return this.canSubmit;
    }
}
