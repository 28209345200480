import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

// import SwiperCore, { Keyboard, Pagination, Scrollbar } from 'swiper';
// import { IonicSlides } from '@ionic/angular';

// SwiperCore.use([Keyboard, Pagination, Scrollbar, IonicSlides]);

@Component({
    selector: 'app-walkthrough',
    templateUrl: './walkthrough.page.html',
    styleUrls: ['./walkthrough.page.scss'],
})
export class WalkthroughPage implements OnInit {

    constructor(
        private navController: NavController,
    ) { }

    ngOnInit() {
    }

    goNextSlide(slides) {
        slides.slideNext();
    }

    continue() {
        this.navController.navigateForward('/login').catch();
    }
}
