/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { Model } from './model';

export class NotificationModel extends Model {

    id: number;
    user_id: number;
    title: string;
    message: string;
    priority: number;
    label: string;
    sms?;
    email?;
    hidden?;
    read_at?: string;
    created_at?: string;

    constructor(data = null) {
        super();
        this.id         = Number(data.id);
        this.user_id    = data.user_id;
        this.title      = data.title;
        this.message    = data.message;
        this.priority   = data.priority;
        this.label      = data.label;
        this.created_at = data.created_at;
        this.read_at    = data.read_at;

        this.sms    = Boolean(data.sms);
        this.email  = Boolean(data.email);
        this.hidden = Boolean(data.hidden);
        // if (!Utils.isEmpty(data)) {
        // }
    }
}
