import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';
import { Utils } from '../../library/utils';
import { CommonService } from './common.service';
import * as Case from 'change-case';
import { ViewHelperService } from './view-helper.service';
import { EventManager } from '../../library/event-manager';
import { BehaviorSubject } from 'rxjs';
import { isArray } from 'rxjs/internal-compatibility';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    cache: Record<any, any> = {};
    publicCache: Record<any, any> = {};

    newGlobalData: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    private cacheLoaded = false;

    constructor(
        private storageService: StorageService,
        private apiService: ApiService,
        private commonService: CommonService,
        private viewHelperService: ViewHelperService,
        private eventManager: EventManager,
    ) {
        // this.loadCache().then();

        eventManager.subscribe('UserLoggedOut', () => {
            this.reset();
        });
    }

    async getDataFromOnline(type = 'primary'): Promise<any> {
        let url = 'primary-data';
        let silent = false;

        if (type === 'secondary') {
            url = 'secondary-data';
            silent = true;
        }

        return new Promise((resolve, reject) => {
            this.apiService.makeOperationRequest(url).then(data => {
                resolve(data);
            }).catch(error => {
                console.log(error);

                if (!silent) {
                    if (error.code === 503) {
                        console.warn('SERVER IN MAINTENANCE MODE');
                        this.viewHelperService.showErrorToast('Maintenance en cours... veuillez patienter un instant', 3000, null, 'OK', 'bottom').then();
                    } else if (error.code === 401) {
                        console.warn('AUTH FAILED');
                        this.viewHelperService.showErrorToast('Veuillez vous reconnecter', 3000, null, 'OK', 'bottom').then();
                        this.eventManager.publish('AuthFailedWhenFetchingData');
                    } else {
                        this.viewHelperService.showErrorToast('Impossible de récupérer les données. Vérifiez votre connexion', 3000, null, 'OK', 'bottom').then();
                    }
                }

                reject('Failed request');
            });
        });
    }

    async getAndStoreData(type = 'primary'): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.getDataFromOnline(type).then(data => {
                console.log('got data form online :: ' + type, data);

                this.storeData(data, type).then(() => {
                    resolve(true);
                });
            }).catch(error => {
                console.error('Error when getting data form online', error);
                reject(error);
            });
        });
    }

    storeData(data, type = 'primary') {
        return new Promise<boolean>(resolve => {
            if (type === 'primary') {
                this.newGlobalData.next(data);

                this.eventManager.publish('PrimaryDataAvailable', data);

                this.storeOtherReceivedData(data);
            }

            if (type === 'secondary') {
                this.eventManager.publish('SecondaryDataAvailable', data);
            }

            resolve(true);
        });
    }

    storeOtherReceivedData(allData: any) {
        if (allData.summary) {
            this.storeAnyData('summary', allData.summary, true, { notify: 'publish' })
                .then().catch((error) => console.error(error));
        }
    }

    async getAnyData(key, isObject = true, useCache = true): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (useCache && this.cache[key] != null) {
                // console.log(key + ' found in cache', this.cache[key]);
                resolve(this.cache[key]);
                return;
            }

            // console.log(key + ' not found in cache');
            if (isObject) {
                this.storageService.getObject(key).then(data => {
                    if (Utils.isNotEmpty(data)) {
                        // console.warn('Data "' + key + '" found', data);
                        this.addToCache(key, data);
                        resolve(data);
                    } else {
                        // console.warn('Data "' + key + '" not found or empty', data);
                        reject(false);
                    }
                });
            } else {
                this.storageService.get(key).then(data => {
                    if (data) {
                        this.addToCache(key, data);
                        resolve(data);
                    } else {
                        // console.warn('Data "' + key + '" not found');
                        reject(false);
                    }
                });
            }
        });
    }

    async storeAnyData(key: string, data, isObject = true, options: Record<any, any> = { notify: false, cache: true }): Promise<any> {
        if (data === null || data === undefined) {
            return null;
        }

        if (isObject) {
            await this.storageService.setObject(key, data).then();
        } else {
            await this.storageService.set(key, data).then();
        }

        if (options.cache !== false) {
            this.addToCache(key, data);
        }

        if (options.notify === true || options.notify === 'publish') {
            this.eventManager.publish(Case.pascalCase(key) + 'Updated', data);
            console.log(Case.pascalCase(key) + 'Updated publish');
        } else if (options.notify === 'queue') {
            this.eventManager.queue(Case.pascalCase(key) + 'Updated', data);
        }

        return data;
    }

    async storeManyData(dataList: Array<{ key: string; data: any; isObject: boolean }>, notify: string | boolean = false, cache = true): Promise<any> {
        const notif = (notify === true || notify === 'publish') ? 'queue' : notify;
        const storedData = {};

        for (const dataItem of dataList) {
            storedData[dataItem.key] = await this.storeAnyData(dataItem.key, dataItem.data, dataItem.isObject ?? true, { notify: notif, cache });
        }

        if (notify === true || notify === 'publish') {
            this.eventManager.dispatchQueue();
        }

        return storedData;
    }

    async removeData(keys: string | Array<string>) {
        if (!isArray(keys)) {
            keys = [keys];
        }

        for (const key of keys) {
            await this.storageService.remove(key);
            delete this.cache[key];
        }
        return true;
    }


    async loadCache() {
        await this.getAnyData('__cache', true, false)
            .then((cache) => {
                this.publicCache = cache ?? {};
                this.cacheLoaded = true;
                console.warn('CACHE LOADED');
            })
            .catch((e) => console.warn('NO CACHE FOUND', e));
    }

    putInCache(key, data, location: 'public' | 'default' = 'public') {
        if (data === null || data === undefined) {
            console.warn('Invalid value for cache key ', key);
            return;
        }

        if (location === 'default') {
            this.publicCache[key] = data;
            return;
        }

        this.publicCache[key] = data;
        this.storageService.set('__cache', JSON.stringify(this.publicCache)).then();
    }

    async getFromCache(key, defaultValue = null, location: 'public' | 'default' = 'public') {
        if (this.cacheLoaded === false) {
            await this.loadCache();
        }

        const cache = location === 'public' ? this.publicCache : this.cache;

        // console.warn('Cache :', cache);

        if (cache[key] === undefined) {
            console.warn('*** DataService : `' + key + '` not found in ' + location + ' cache');
            return defaultValue;
        }

        console.warn('Found in cache :', key, cache[key]);
        return cache[key];
    }

    private reset() {
        this.cache = {};
        this.publicCache = {};
    }

    private addToCache(key, data) {
        this.cache[key] = data;
    }
}
