import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-dashboard-tile',
    templateUrl: './dashboard-tile.component.html',
    styleUrls: ['./dashboard-tile.component.scss'],
})
export class DashboardTileComponent implements OnInit {

    @Input() icon: string;
    @Input() value: any;
    @Input() label1: string;
    @Input() label2: string;
    @Input() color;

    @Input() align: 'center'|'start'|'end'  = 'start';
    @Input() size: 'large'|'small'|'normal' = 'normal';

    constructor(private dataService: DataService) {
    }

    ngOnInit() {
        if (this.color === null || this.color === undefined) {
            this.color = 'primary';
            // this.dataService.getFromCache('packageColor', 'primary').then((color) => {
            //     this.color = color;
            //     // console.warn(this.color);
            // });
        }
    }
}
