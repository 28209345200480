import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserAccountService } from '../../services/user-account.service';
import { EventManager } from '../../../library/event-manager';
import { UserModel } from '../../entities/user.model';
import { AccountHead, AccountModel } from '../../entities/account.model';
import { ViewHelperService } from '../../services/view-helper.service';
import { AuthService } from '../../services/auth.service';
import { UpdateUserInfosModal } from '../../modals/update-user-infos/update-user-infos.modal';
import { ModalController, NavController } from '@ionic/angular';
import { UpdateAccountHeadModal } from 'src/app/modals/update-account-head/update-account-head.modal';
import { AccountDetailsUpdateModal } from 'src/app/modals/account-details-update/account-details-update.modal';
import { RELEASE } from "src/shared/app-infos";

@Component({
    selector: 'app-account',
    templateUrl: 'account.page.html',
    styleUrls: ['account.page.scss']
})
export class AccountPage implements OnInit, OnDestroy {

    user: UserModel = null;
    account = new AccountModel({});

    heads = [];
    headModalOpened = false;

    readonly appRelease = RELEASE;
    readonly userUpdatedEventHandler;
    readonly accountUpdatedEventHandler;
    private lastLogOutPressedStamp: number;

    constructor(
        private navController: NavController,
        public modalController: ModalController,
        private userAccountService: UserAccountService,
        private viewHelperService: ViewHelperService,
        private authService: AuthService,
        private eventManager: EventManager,
    ) {
        this.userUpdatedEventHandler = (user) => this.loadUser(user);
        this.accountUpdatedEventHandler = (account) => this.loadAccount();
    }

    ngOnInit() {
        this.loadAccount();
        this.loadUser();

        this.eventManager.subscribe('UserUpdated', this.userUpdatedEventHandler);
        this.eventManager.subscribe('AccountUpdated', this.accountUpdatedEventHandler);
    }

    ngOnDestroy() {
        this.eventManager.unsubscribe('UserUpdated', this.userUpdatedEventHandler);
        this.eventManager.unsubscribe('AccountUpdated', this.accountUpdatedEventHandler);
    }

    logOut() {
        if ((Date.now() - this.lastLogOutPressedStamp) < 1000) {
            this.viewHelperService.showLoader('Déconnexion en cours ...').then(() => {
                this.authService.signOut().then(() => {
                    this.viewHelperService.hideLoader();
                    this.viewHelperService.showToast('Vous avez été deconnecté avec succès !', 4000, 'Déconnecté !').then();
                });
            });
        } else {
            this.lastLogOutPressedStamp = Date.now();
            this.viewHelperService.showToast(
                'Appuyez encore une fois pour vous déconnecter',
                800,
                null,
                null,
                null,
                'opacity-95',
            ).then();
        }
    }

    showPasswordUpdateModal() {
        this.modalController.create({
            component: UpdateUserInfosModal,
            cssClass: 'floating',
            componentProps: { operationType: 'password' },
            breakpoints: [0, 0.95],
            initialBreakpoint: 0.95,
        }).then(modal => {
            modal.present().then();
        });
    }

    startHeadDraft(head = null) {
        this.modalController.create({
            component: UpdateAccountHeadModal,
            cssClass: '',
            componentProps: { head },
            breakpoints: [0, 0.95],
            initialBreakpoint: 0.95,
        }).then(modal => {
            modal.present().then();
        });
    }

    showAccountUpdateModal(action = 'update') {
        this.modalController.create({
            component: AccountDetailsUpdateModal,
            cssClass: '',
            componentProps: { action },
            breakpoints: [0, 0.95],
            initialBreakpoint: 0.95,
        }).then(modal => {
            modal.present().then();
        });
    }

    private loadUser(user = null) {
        if (user) {
            this.user = user;
            return;
        }
        this.userAccountService.getUser().then((u) => this.user = u);
    }
    private loadAccount() {
        this.userAccountService.getAccount().then((account) => {
            account.heads = account.heads ?? [];

            this.account = account;

            for (let i = 0; i < 3; i++) {
                this.heads[i] = account.heads[i] ?? null;
            }
        });
    }
}
