import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegistrationPage } from './registration.page';

const routes: Routes = [
    {
        path: '',
        component: RegistrationPage
    },
    {
        path: 'confirmation',
        loadChildren: () => import('../registration-confirmation/registration-confirmation.module').then(m => m.RegistrationConfirmationPageModule)
    },
    {
        path: 'success',
        loadChildren: () => import('../registration-success/registration-success.module').then(m => m.RegistrationSuccessPageModule)
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RegistrationPageRoutingModule {
}
