/* eslint-disable @typescript-eslint/naming-convention */

export const PROJECTS = [
    {
        id: 120,
        account_id: 1,
        account: {
            denomination: 'Coopérative ABCD'
        },
        title: 'Premier projet de Soja Bio',
        description: 'Nous avons repris le domaine et ses 20 hectares de vergers de pommiers en agriculture raisonnée, afin de développer la marque historique et les nouvelles marques de rhum et de whisky, ainsi qu\'une activité touristique pour valoriser le Château et ses environs.\n' +
            'Aujourd’hui, Château du Breuil est une marque connue pour son Calvados AOP pays d’Auge. La Spiriterie Française, Château du Breuil Normandie, produit chaque année environ 420 000 bouteilles de Calvados et est, à ce titre, le 2e plus grand producteur AOP Pays d’Auge. \n' +
            'Le Calvados Pays d’Auge est élaboré à partir de la distillation à la repasse de cidres issus de fruits à cidre provenant de vergers en agriculture raisonnée du Pays d’Auge. Nos produisons nos cidres sur nos vergers avec des pommes riches en composés phénoliques apportant la saveur caractéristique de nos produits.' +
            ' Ensuite cette eau de vie est élevée en fût pendant au moins deux ans au château, le tout en favorisant toujours l\'utilisation de matériaux éco-responsables.',
        image: '/assets/images/dummy-project-1.jpg',
        status: 'under_investment',
        business_plan: null,
        investment_progress: null,
        total_investment: 1250000,
        total_collected: 850000,
        total_duration: 24,
        other_images: {},
        country_id: 220,
        country: { name: 'Togo' },
        region: 'Plateau',
        summary: {
            rentability: 25
        }
    },
    {
        id: 121,
        account_id: 1,
        account: {
            denomination: 'COOPE-TEST AVE'
        },
        title: 'Extension de plantations de Sorgho',
        description: 'Nous avons repris le domaine et ses 20 hectares de vergers de pommiers en agriculture raisonnée, afin de développer la marque historique et les nouvelles marques de rhum et de whisky, ainsi qu\'une activité touristique pour valoriser le Château et ses environs.\n' +
            'Aujourd’hui, Château du Breuil est une marque connue pour son Calvados AOP pays d’Auge. La Spiriterie Française, Château du Breuil Normandie, produit chaque année environ 420 000 bouteilles de Calvados et est, à ce titre, le 2e plus grand producteur AOP Pays d’Auge. \n' +
            'Le Calvados Pays d’Auge est élaboré à partir de la distillation à la repasse de cidres issus de fruits à cidre provenant de vergers en agriculture raisonnée du Pays d’Auge. Nos produisons nos cidres sur nos vergers avec des pommes riches en composés phénoliques apportant la saveur caractéristique de nos produits.' +
            ' Ensuite cette eau de vie est élevée en fût pendant au moins deux ans au château, le tout en favorisant toujours l\'utilisation de matériaux éco-responsables.',
        image: '/assets/images/dummy-project-2.jpg',
        status: 'under_investment',
        business_plan: null,
        investment_progress: null,
        total_investment: 4500000,
        total_collected: 1326000,
        total_duration: 48,
        other_images: {},
        country_id: 220,
        country: { name: 'Togo' },
        region: 'Maritime',
        summary: {
            location: 'Keve ouest',
            rentability: 20
        }
    }
];

export const OWN_PROJECT = {
    id: 122,
    account_id: 1,
    account: {
        denomination: 'COOPE-TEST AVE'
    },
    title: 'Maraîchage moderne',
    description: 'Nous avons repris le domaine et ses 20 hectares de vergers de pommiers en agriculture raisonnée, afin de développer la marque historique et les nouvelles marques de rhum et de whisky, ainsi qu\'une activité touristique pour valoriser le Château et ses environs.\n' +
        'Aujourd’hui, Château du Breuil est une marque connue pour son Calvados AOP pays d’Auge. La Spiriterie Française, Château du Breuil Normandie, produit chaque année environ 420 000 bouteilles de Calvados et est, à ce titre, le 2e plus grand producteur AOP Pays d’Auge. \n' +
        'Le Calvados Pays d’Auge est élaboré à partir de la distillation à la repasse de cidres issus de fruits à cidre provenant de vergers en agriculture raisonnée du Pays d’Auge. Nos produisons nos cidres sur nos vergers avec des pommes riches en composés phénoliques apportant la saveur caractéristique de nos produits.' +
        ' Ensuite cette eau de vie est élevée en fût pendant au moins deux ans au château, le tout en favorisant toujours l\'utilisation de matériaux éco-responsables.',
    image: '/assets/images/dummy-project-3.jpg',
    status: 'under_investment',
    business_plan: null,
    investment_progress: null,
    total_investment: 25000000,
    total_collected: 5000000,
    total_duration: 36,
    other_images: {},
    country_id: 220,
    country: { name: 'Togo' },
    region: 'Maritime',
    summary: {
        location: 'Danyi elavanyo',
        rentability: 30
    }
};

export const COUNTRIES = [
    {
        id: 24,
        name: 'Benin',
        code: '229',
        iso3: 'BEN',
        iso2: 'BJ',
        capital: 'Porto-Novo',
        currency: 'XOF',
        active: null,
        flag: 1,
        created_at: '2018-07-20 14:41:03',
        updated_at: '2018-07-20 14:41:03'
    },
    {
        id: 35,
        name: 'Burkina Faso',
        code: '226',
        iso3: 'BFA',
        iso2: 'BF',
        capital: 'Ouagadougou',
        currency: 'XOF',
        active: null,
        flag: 1,
        created_at: '2018-07-20 14:41:03',
        updated_at: '2018-07-20 14:41:03'
    },
    {
        id: 42,
        name: 'Central African Republic',
        code: '236',
        iso3: 'CAF',
        iso2: 'CF',
        capital: 'Bangui',
        currency: 'XAF',
        active: null,
        flag: 1,
        created_at: '2018-07-20 14:41:03',
        updated_at: '2018-07-20 14:41:03'
    },
    {
        id: 220,
        name: 'Togo',
        code: '228',
        iso3: 'TGO',
        iso2: 'TG',
        capital: 'Lome',
        currency: 'XOF',
        active: null,
        flag: 1,
        created_at: '2018-07-20 14:41:03',
        updated_at: '2018-07-20 14:41:03'
    }
];
