import { Model } from './model';
import { Utils } from '../../library/utils';

abstract class DTO {
    public constructor(data: Record<string, any> = null) {
        if (! Utils.isEmpty(data)) {
            Object.assign(this, data);
        }
    }
}

export class RegistrationData extends DTO {
    type;
    denomination;
    locality;
    phone;
    email;
    firstname;
    lastname;
    password;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    country_id;
}

export class TodoData extends DTO {
    key: string;
    title: string;
    icon;
    action: Record<string, string>;
}
