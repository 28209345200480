import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccountPage } from './account.page';

import { AccountPageRoutingModule } from './account-routing.module';
import { UpdateUserInfosModalModule } from '../../modals/update-user-infos/update-user-infos.module';
import { UpdateAccountHeadModalModule } from 'src/app/modals/update-account-head/update-account-head.module';
import { AccountDetailsUpdateModalModule } from 'src/app/modals/account-details-update/account-details-update.module';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        AccountPageRoutingModule,
        UpdateUserInfosModalModule,
        UpdateAccountHeadModalModule,
        AccountDetailsUpdateModalModule
    ],
    declarations: [AccountPage]
})
export class AccountPageModule {
}
