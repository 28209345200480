/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { DataService } from './data.service';
import { UserModel } from '../entities/user.model';
import { EventManager } from '../../library/event-manager';
import { UserAccountService } from './user-account.service';
import { APP } from '../../shared/app-infos';
import { Utils } from '../../library/utils';
import { RegistrationData } from '../entities/misc-entities';
import { AccountModel } from '../entities/account.model';
import { COUNTRIES, OWN_PROJECT } from '../../shared/dummy-data';
import { ProjectService } from './project.service';
import { ORG_TYPES } from 'src/shared/data';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    justLoggedIn           = false;
    appStatus: string|null = null;

    registrationData: RegistrationData = null;

    constructor(
        private apiService: ApiService,
        private storageService: StorageService,
        private dataService: DataService,
        private userAccountService: UserAccountService,
        private projectService: ProjectService,
        private eventManager: EventManager
    ) {
        this.loadSavedRegistrationData().catch();
        // this.eventManager.subscribe('UserLoggedOut', () => {
        //   console.log('event UserLoggedOut catched');
        //   this.reset();
        // });
    }

    signIn(username, password) {
        return new Promise((resolve, reject) => {
            if (APP.isOfflineDemo) {
                this.executeFakeLogin(username, password)
                    .then((data) => resolve(data))
                    .catch((err) => reject(err));
                return;
            }

            this.apiService.post('auth/login', { username, password }).subscribe(
                (result) => {
                    console.log(result);
                    if (result.status === 'success') {
                        this.processSuccessAuth(result.data).then(() => {
                            resolve(result.data);
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {
                        reject(result);
                    }
                },
                (error) => {
                    console.error(error);
                    reject(error.error);
                });
        });
    }

    signUp(inputs: RegistrationData) {
        console.log('Attempt Sign Up', inputs);
        return new Promise<any>((resolve, reject) => {
            if (APP.isOfflineDemo) {
                resolve(this.startFakeRegistration(inputs));
                return;
            }

            // noinspection DuplicatedCode
            this.saveRegistrationData(inputs);
            this.apiService.post('auth/registration/submit', inputs).subscribe((result) => {
                console.log(result);
                if (result.status === 'success') {
                    this.saveConfirmationToken(result.data).then(() => {
                        resolve(result.data);
                    }).catch((error) => {
                        reject(error);
                    });
                } else {
                    reject(result);
                }
            }, (error) => {
                console.error(error);
                reject(error.error);
            });
        });
    }

    async confirmRegistration(code) {
        console.log('Attempt confirmation', code);
        const token = await this.fetchConfirmationToken();

        return new Promise<any>((resolve, reject) => {
            if (APP.isOfflineDemo) {
                this.makeFakeConfirmation().then(() => resolve(true));
                return;
            }


            // noinspection DuplicatedCode
            this.apiService.post('auth/registration/confirm', { code, token }).subscribe((result) => {
                console.log(result);
                if (result.status === 'success') {
                    this.processSuccessAuth(result.data).then(() => {
                        resolve(result.data);
                    }).catch((error) => {
                        reject(error);
                    });
                } else {
                    reject(result);
                }
            }, (error) => {
                console.error(error);
                reject(error.error);
            });
        });
    }

    async signOut(publish = true) {
        if (APP.isOfflineDemo) {
            await this.executeFakeLogout(publish);
            return;
        }

        await this.apiService.makeOperationRequest('auth/logout').then(data => {
            console.log('Logged out on server successfully', data);
        }).catch(error => {
            console.error(error);
        });

        await this.storageService.clear();

        this.appStatus = 'loggedOut';
        this.storageService.set('status', this.appStatus).then();

        this.reset();

        if (publish) {
            this.eventManager.publish('UserLoggedOut');
        }

        return true;
    }

    async verifyPassword(password: string) {
        return new Promise((resolve, reject) => {
            this.apiService.makeOperationRequest('auth/check-password', { password }).then(data => {
                resolve(data.is_correct);
            }).catch(error => {
                reject(error);
            });
        });
    }

    async isLoggedIn() {
        return new Promise<boolean>((resolve, reject) => {
            if (this.appStatus === 'authenticated') {
                resolve(true);
            }

            this.storageService.get('status').then((status) => {
                console.log('App Status Loaded', status);
                this.appStatus = status;
                if (status === 'authenticated') {
                    this.eventManager.publish('UserIsAuthenticated');
                    resolve(true);
                } else {
                    reject(false);
                }
            }).catch(() => {
                reject(false);
            });
        });

        // if (this.appStatus === 'authenticated') {
        //   return true;
        // }
        //
        // return await this.storageService.get('status').then((status) => {
        //   this.appStatus = status;
        //   if (status === 'authenticated') {
        //     this.eventManager.publish('UserIsAuthenticated');
        //     return true;
        //   }
        //   return false;
        // }).catch(() => {
        //   return false;
        // });
    }

    async loadSavedRegistrationData() {
        const registrationData = await this.dataService.getAnyData('__registrationData');

        if (registrationData) {
            this.registrationData = new RegistrationData(registrationData);
        }

        return this.registrationData;
    }

    async fetchConfirmationToken() {
        return await this.dataService.getAnyData('__regConfirmationToken');
    }

    reset() {
        this.justLoggedIn = false;
        this.appStatus    = null;
    }

    private async processSuccessAuth(data) {
        return new Promise<boolean>((resolve, reject) => {
            Promise.all([
                this.userAccountService.saveRawUser(data.user),
                this.userAccountService.saveRawAccount(data.account),
                this.apiService.storeNewKey(data.key, data.api_key),
            ]).then(() => {
                this.appStatus = 'authenticated';
                this.storageService.set('status', this.appStatus);
                this.eventManager.publish('UserIsAuthenticated');

                console.log('status saved successfully');
                resolve(true);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    private saveRegistrationData(inputs: RegistrationData) {
        this.dataService.storeAnyData('__registrationData', inputs).then();
    }

    private startFakeRegistration(inputs: RegistrationData) {
        this.registrationData = new RegistrationData(inputs);

        console.log(this.registrationData);

        this.dataService.storeAnyData('__registrationData', this.registrationData).then();

        this.appStatus = 'awaitingConfirmation';
        this.storageService.set('status', this.appStatus).then();

        return this.registrationData;
    }

    private async makeFakeConfirmation() {
        const id = Utils.randomInt(1000, 9999);

        const user    = new UserModel({});
        const account = new AccountModel({});

        user.id         = id;
        user.account_id = id;
        user.firstname  = this.registrationData.firstname;
        user.lastname   = this.registrationData.lastname;
        user.phone      = this.registrationData.phone;
        user.email      = this.registrationData.email;
        user.password   = this.registrationData.password;

        account.id                = id;
        account.username          = id;
        account.denomination      = this.registrationData.denomination;
        account.email             = this.registrationData.email;
        account.phone             = this.registrationData.phone;
        // account.password          = this.registrationData.password;
        account.locality          = this.registrationData.locality;
        account.organization_type = ORG_TYPES[this.registrationData.type];
        account.country_id        = this.registrationData.country_id;
        // eslint-disable-next-line eqeqeq
        account.country           = COUNTRIES.find((country) => country.id == this.registrationData.country_id);
        account.heads             = [{
            id,
            account_id: id,
            firstname: this.registrationData.firstname,
            lastname: this.registrationData.lastname,
            phone: this.registrationData.phone,
            is_leader: true
        }];

        await this.processSuccessAuth({
            user,
            account,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            api_key: 'nfd9ahr928dn4osu4k3nwh40x9wm334w',
            key: null,
        });


        const ownProject = OWN_PROJECT;

        ownProject.account_id           = id;
        ownProject.account.denomination = account.denomination;

        await this.projectService.addToLocalProjects(ownProject);

        return true;
    }

    private async executeFakeLogin(username, password) {
        return new Promise<any>((resolve, reject) => {
            this.dataService.getAnyData('oldAccount')
                .then((account) => {
                    console.log('Old account found :', account);
                    console.log('username & password :', username, password);
                    // eslint-disable-next-line eqeqeq
                    if (account.username == username && account.password == password) {
                        this.dataService.getAnyData('oldUser').then((user) => {
                            this.processSuccessAuth({ account, user, key: null, api_key: '8sjwy2hzl445' }).then(() => {
                                resolve({ account, user });
                            }).catch(() => {
                                reject({ error_code: 'Unauthorized' });
                            });
                        });
                    } else {
                        reject({ error_code: 'Unauthorized' });
                    }
                })
                .catch(() => {
                    console.warn('No old account found');
                    reject({ error_code: 'Unauthorized' });
                });
        });
    }

    private async executeFakeLogout(publish = true) {
        const currentUser = await this.userAccountService.getUser();
        console.log(currentUser);

        const currentAccount = await this.userAccountService.getAccount();
        console.log(currentAccount);

        await this.storageService.clear();

        this.appStatus = 'loggedOut';
        this.storageService.set('status', this.appStatus).then();

        this.storageService.setObject('oldUser', currentUser).then();
        this.storageService.setObject('oldAccount', currentAccount).then();

        this.reset();

        if (publish) {
            this.eventManager.publish('UserLoggedOut');
        }

        return true;

    }

    private async saveConfirmationToken(data) {
        await this.dataService.storeAnyData('__regConfirmationToken', data.token);
    }
}
