/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */
import { Model } from './model';
import { dateTool, Utils } from '../../library/utils';
import { SERVER_URL } from 'src/environments/environment';

export class ProjectModel extends Model {

    public id: number;
    public account_id;
    public title;
    public description;
    public image;
    public status;
    public business_plan;
    public investment_progress;
    public total_investment;
    public total_collected;
    public total_duration;
    public other_images;
    public country_id;
    public country: Record<any, any>;
    public region;

    public statusText: string;
    public statusColor: string;
    public statusStyling = 'bg-green-50 text-green-700';
    public durationInYear = 1;
    public imageUrl = '';
    public businessPlanUrl = '';
    // public dateForHuman: string;

    public summary: {
        domain: string;
        main_goal: string;
        location: string;
        investment_amount: number;
        capital_requirement_amount: number;
        total_cost: number;
        self_contribution: number;
        required_contribution: number;
        sales_amount: number;
        added_value: number;
        profitability: any;
        duration: number;
        key_indicators: Record<any, any>;
    };

    public notes = [];

    public account: {
        denomination: string;
    };

    products: any[];

    constructor(data) {
        super(data);

        if (Utils.isEmpty(this.country)) {
            this.country = {};
        }

        // COMPUTED PROPERTIES
        this.statusText = this.getStatusText();
        this.statusColor = this.getStatusColor();
        this.statusStyling = this.getStatusStyling();
        this.imageUrl = SERVER_URL + 'files/projects/images/' + this.image;

        if (this.investment_progress == null) {
            this.investment_progress = Math.floor((this.total_collected / this.total_investment) * 100);
        }

        if (this.updated_at == null) {
            this.updated_at = dateTool().subtract(Utils.randomInt(10, 240), 'minutes').toISOString();
        }

        // console.log(this.updated_at);
    }

    public getStatusText() {
        const table = {
            pending: 'en attente de ...',
            under_validation: 'en cours de validation',
            processing: 'en cours de validation',
            active: 'Validé, en cours',
            under_investment: 'en cours d\'investissement'
        };

        return table[this.status] ?? this.status;
    }

    public getStatusStyling() {
        const table = {
            pending: 'bg-yellow-50 text-yellow-600',
            under_validation: 'en cours de validation',
            processing: 'en cours de validation',
            active: 'bg-green-50 text-green-700',
            under_investment: 'en cours d\'investissement'
        };

        return table[this.status] ?? this.status;
    }

    public getStatusColor() {
        const table = {
            pending: 'warning',
            under_validation: 'warning',
            processing: 'warning',
            active: 'success',
            under_investment: 'primary'
        };

        return table[this.status] ?? this.status;
    }
}
