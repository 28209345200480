/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Utils } from '../../library/utils';
import { ProjectModel } from '../entities/project.model';
import { EventManager } from '../../library/event-manager';
import { ProjectDraft } from '../entities/project.draft';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    private projects: ProjectModel[] = [];
    private accountProjects: ProjectModel[] = [];

    constructor(
        private dataService: DataService,
        private apiService: ApiService,
        private eventManager: EventManager
    ) {
        eventManager.subscribe('DeprecatedDataWiped', () => {
        });
    }

    loadSavedDraft() {
        return new Promise<ProjectDraft>((resolve, reject) => {
            this.dataService.getAnyData('projectDraft', true, false).then((draft) => {
                if (Utils.isEmpty(draft)) {
                    reject('Empty draft');
                    return;
                }

                resolve(new ProjectDraft(draft));
            }).catch(() => {
                reject('No draft found');
            });
        });
    }

    saveDraft(draft: ProjectDraft) {
        return new Promise((resolve) => {
            this.dataService.storeAnyData('projectDraft', draft)
                .then(() => resolve(true))
                .catch((err) => console.warn('ERROR : ', err));
        });
    }

    deleteSavedDraft() {
        return new Promise((resolve) => {
            this.dataService.removeData('projectDraft')
                .then(() => resolve(true))
                .catch((err) => console.warn('ERROR : ', err));
        });
    }

    submitProject(draft: ProjectDraft) {
        return new Promise<any>((resolve, reject) => {
            this.apiService.makeOperationRequest('projects/create', draft.getInputs())
                .then((data) => {
                    this.storeProjectsData(data);
                    this.deleteSavedDraft();
                    resolve(true);
                })
                .catch((err) => {
                    console.error(err);
                    reject('Failed');
                });
        });
    }

    updateProject(draft: ProjectDraft) {
        return new Promise<any>((resolve, reject) => {
            this.apiService.makeOperationRequest('projects/update', draft.getInputs())
                .then((data) => {
                    this.storeProjectsData(data);
                    // this.deleteSavedDraft();
                    resolve(true);
                })
                .catch((err) => {
                    console.error(err);
                    reject('Failed');
                });
        });
    }

    getProjects() {
        console.log('@getProjects');
        return new Promise<ProjectModel[]>(resolve => {
            if (Utils.isNotEmpty(this.projects)) {
                console.warn(this.projects);
                resolve(this.projects);
                return;
            }

            this.dataService.getAnyData('projects').then((projects) => {
                console.warn('OKOK', projects);

                this.processProjects(projects);
                resolve(this.projects);

            }).catch(() => {
                console.log('No project yet');
                resolve([]);
                // this.addDemoData().then((demoProjects) => {
                //     this.projects = demoProjects.map((p) => new ProjectModel(p));
                //     resolve(this.projects);
                // });
            });
        });
    }

    getProject(id) {
        return new Promise<ProjectModel>((resolve, reject) => {
            this.getProjects().then((projects) => {
                // eslint-disable-next-line eqeqeq
                const project = projects.find((el) => el.id == id);
                if (project) {
                    resolve(project);
                } else {
                    reject();
                }
            });
        });
    }

    getAccountProjects(accountId: number) {
        return new Promise<ProjectModel[]>((resolve) => {
            this.getProjects().then((projects) => {
                // eslint-disable-next-line eqeqeq
                this.accountProjects = projects.filter((project) => project.account_id == accountId);
                console.log('accountProjects', this.accountProjects);
                resolve(this.accountProjects);
            });
        });
    }

    // getDraftProjects() {
    //     return new Promise<ProjectModel[]>((resolve) => {
    //         this.dataService.getAnyData('draftProjects')
    //             .then((drafts) => {
    //                 console.log(drafts);
    //                 resolve(drafts.map((draft) => new ProjectModel(draft)));
    //             })
    //             .catch((error) => {
    //                 console.warn(error);
    //                 resolve([]);
    //             });
    //     });
    // }

    addToLocalProjects(project) {
        return new Promise<ProjectModel[]>((resolve) => {
            this.getProjects().then((projects) => {
                projects.push(new ProjectModel(project));
                this.projects = projects;
                this.dataService.storeAnyData('projects', projects).then(() => {
                    this.eventManager.publish('ProjectsUpdated', projects);
                });
                resolve(this.projects);
            });
        });
    }

    storeProjectsData(allData: any) {
        if (allData.projects) {
            this.dataService.storeAnyData('projects', allData.projects, true, { cache: true, notify: false }).then(() => {
                this.processProjects(allData.projects);
                this.eventManager.publish('ProjectsUpdated', this.projects);
            });
        }
    }

    // private async addDemoData() {
    //     const data = PROJECTS;
    //
    //     console.warn('DemoData', data);
    //     await this.dataService.storeAnyData('projects', data, true, { notify: true });
    //
    //     return data;
    // }

    private processProjects(projects) {
        this.projects = projects.map((p) => new ProjectModel(p));
    }
}
