import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UpdateUserInfosModal } from './update-user-infos.modal';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule
    ],
    declarations: [UpdateUserInfosModal],
    entryComponents: [UpdateUserInfosModal]
})
export class UpdateUserInfosModalModule {
}
