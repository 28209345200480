import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-profile-photo',
    templateUrl: './profile-photo.component.html',
    styleUrls: ['./profile-photo.component.scss'],
})

export class ProfilePhotoComponent implements OnInit {

    @Input() url: string|null;
    @Input() fallbackUrl: string|null = 'assets/images/avatar.svg';

    @Input() useBorder = true;
    @Input() useShadow = true;

    constructor() {
    }

    ngOnInit() {
    }

}
