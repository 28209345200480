/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { Model } from './model';
import { Utils } from '../../library/utils';
import { ORG_TYPES } from 'src/shared/data';

export class AccountModel extends Model {

    id: number;
    username: any;
    denomination: string;
    organization_type: string;
    phone: string;
    email: string;
    address;
    locality;
    region;
    country_id;
    confirmed: any;
    created_at;
    updated_at;
    country: any;

    heads: Array<AccountHead>;

    profile: Record<string, any>;

    orgType: string = null;

    constructor(data) {
        super(data);

        console.log(this);

        if (Utils.isEmpty(this.country)) {
            this.country = {};
        }
        if (this.profile === undefined || this.profile === null) {
            this.profile = {
                activity_domain: '',
                members_count: '',
                creation_date: '',
            };
        }

        // COMPUTED PROPERTIES
        this.orgType = ORG_TYPES[this.organization_type];
    }
}

export class AccountHead extends Model {
    id;
    account_id;
    position?;
    lastname;
    firstname;
    phone;
    id_card_type?;
    id_card_number?;
    id_card_image_1?;
    id_card_image_2?;
    is_leader;
}
