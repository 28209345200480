import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PRODUCTS } from 'src/shared/data';
import _ from 'underscore';

@Component({
    selector: 'app-product-selection',
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent implements OnInit, OnDestroy {

    @Input() selected = [];

    products = [];

    constructor(
        public modalController: ModalController,
    ) {
    }

    ngOnInit() {
        const selectIds = _.pluck(this.selected, 'id') ?? [];
        // this.selected.forEach((el) => {
        //     selectIds.push(el.id);
        // });
        console.log('selectIds', selectIds);

        this.products = PRODUCTS.filter(p => ! selectIds.includes(p.id));
    }

    ngOnDestroy() {
    }

    select(product) {
        this.modalController.dismiss({
            product
        });
    }
}
