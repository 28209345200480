import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { UpdateAccountHeadModal } from './update-account-head.modal';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule
    ],
    declarations: [UpdateAccountHeadModal],
    entryComponents: [UpdateAccountHeadModal]
})
export class UpdateAccountHeadModalModule {
}
