import { Injectable } from '@angular/core';

export type EventHandler = (...args: any[]) => any;

@Injectable({
    providedIn: 'root',
})

export class EventManager {
    private c = new Map<string, EventHandler[]>();
    private q = new Map<string, any>();

    constructor() {
        // console.warn(`Using Custom Event System`);
    }

    /**
     * Subscribe to an event topic. Events that get posted to that topic will trigger the provided handler.
     *
     * @param topic the topic to subscribe to
     * @param handlers the event handler
     */
    subscribe(topic: string, ...handlers: EventHandler[]) {
        let topics = this.c.get(topic);
        if (! topics) {
            this.c.set(topic, topics = []);
        }
        topics.push(...handlers);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * Unsubscribe from the given topic. Your handler will no longer receive events published to this topic.
     *
     * @param topic the topic to unsubscribe from
     * @param handler the event handler
     *
     * @return true if a handler was removed
     */
    unsubscribe(topic: string, handler?: EventHandler): boolean {
        if (! handler) {
            return this.c.delete(topic);
        }

        const topics = this.c.get(topic);
        if (! topics) {
            return false;
        }

        // We need to find and remove a specific handler
        const index = topics.indexOf(handler);

        if (index < 0) {
            // Wasn't found, wasn't removed
            return false;
        }
        topics.splice(index, 1);
        if (topics.length === 0) {
            this.c.delete(topic);
        }

        return true;
    }

    // noinspection JSCommentMatchesSignature
    /**
     * Publish an event to the given topic.
     *
     * @param topic the topic to publish to
     * @param eventData the data to send as the event
     */
    publish(topic: string, ...args: any[]): any[]|null {
        const topics = this.c.get(topic);
        if (! topics) {
            return null;
        }
        return topics.map(handler => {
            try {
                return handler(...args);
            } catch (e) {
                console.error(e);
                return null;
            }
        });
    }

    queue(topic: string, ...args: any[]) {
        this.q.set(topic, args);
        console.warn('Added to event queue', topic);
    }

    dispatchQueue() {
        console.warn('Puslishing from event queue', this.q);
        for (const [topic, args] of this.q) {
            this.publish(topic, args);
        }

        this.q.clear();
    }

    // test() {
    //   let events = {};
    //   events['first'] = new Subject();
    //   events['second'] = new Subject();
    //
    //   console.log(events);
    //   const subscription = events['first'].subscribe((val) => {
    //     console.log('val received from first', val);
    //   });
    //   events['first'].subscribe((val) => {
    //     console.log('again', val);
    //   });
    //
    //   events['first'].next('testVal1');
    //   subscription.unsubscribe();
    //   events['first'].next(1);
    //
    //   events['second'].next('testVal2');
    // }

    // logEvents() {
    //     console.log(this.c.values());
    // }
}
