import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ViewHelperService } from '../../services/view-helper.service';
import { AuthService } from '../../services/auth.service';
import { Utils } from '../../../library/utils';
import { COUNTRIES } from '../../../shared/dummy-data';
import { RegistrationData } from '../../entities/misc-entities';
import { ORG_TYPES } from 'src/shared/data';
// import { UserModel } from '../../entities/user.model';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.page.html',
    styleUrls: ['./registration.page.scss'],
})
export class RegistrationPage implements OnInit {

    regData = new RegistrationData({
        type: '1',
        denomination: '',
        locality: '',
        phone: '',
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        country_id: 220
    });

    countries = COUNTRIES;
    orgTypes = ORG_TYPES;

    termsAccepted = false;

    errorMessage                = 'Veuillez remplir correctement le formulaire';
    errors: Record<string, any> = {};

    showPassword = false;

    constructor(
        private navController: NavController,
        private viewHelperService: ViewHelperService,
        private authService: AuthService
    ) {
        // setInterval(() => console.log(this.termsAccepted), 2000);
    }

    ngOnInit() {
    }

    submit() {
        console.log('Reg. data :', this.regData);

        if (! this.canSubmit()) {
            this.viewHelperService.showToast(this.errorMessage, 3000).then();
            return;
        }

        this.viewHelperService.showLoader('Demande en cours ...').then(() => {
            this.authService.signUp(this.regData)
                .then((result) => {
                    console.log(result);
                    this.continue();
                })
                .catch((error) => {
                    console.log('Error', error);
                    if (error.status === 'fail') {
                        let errorInfos = '<br>';
                        if (error.error_code === 'Validation Failed') {
                            this.viewHelperService.showAlert('Données incorrectes', 'Informations soumises sont invalides. Veuillez bien remplir le formulaire', 'Rectifier').then();
                        } else if (error.error_code === 'Checkings Failed') {
                            for (const key of Object.keys(error.data)) {
                                if (error.data[key].status !== true) {
                                    errorInfos += '<br><span class="text-color-danger">◆ ' + error.data[key].message + '</span>';
                                }
                            }
                            this.viewHelperService.showAlert('Désolé', 'Une erreur s\'est produite lors de la création du compte. Veuillez revérifier les données soumises. ' + errorInfos, 'Rectifier').then();
                        } else {
                            for (const key of Object.keys(error.data)) {
                                errorInfos += '<br><span class="text-color-danger">◆ ' + error.data[key][0] + '</span>';
                            }
                            this.viewHelperService.showAlert('Désolé', 'Une erreur s\'est produite lors de la création du compte. Veuillez revérifier les données soumises. ' + errorInfos, 'Rectifier').then();
                        }
                    } else {
                        this.viewHelperService.showAlert('Connexion impossible', 'Nous ne pouvons pas joindre le serveur. Veuillez vérifier votre connectivité à internet et réessayer').then();
                    }
                })
                .finally(() => {
                    this.viewHelperService.hideLoader();
                });
        });
    }

    continue() {
        this.viewHelperService.hideLoader();

        this.navController.navigateRoot('registration/confirmation').then();
    }

    private canSubmit() {
        let canSubmit = true;
        this.errors   = {};

        // Phone
        this.regData.phone = Utils.sanitizePhone(this.regData.phone);
        if (this.regData.phone.length !== 8) {
            canSubmit         = false;
            this.errors.phone = this.regData.phone.length === 0 ? 'Veuillez saisir votre téléphone' : 'Numéro invalide';
        }

        // Name
        if (Utils.isEmpty(this.regData.denomination) || this.regData.denomination.length < 3) {
            canSubmit                = false;
            this.errors.denomination = 'Dénomination invalide';
        }

        // Name
        if (Utils.isEmpty(this.regData.lastname) || this.regData.firstname.length < 2) {
            canSubmit            = false;
            this.errors.lastname = 'Nom invalide';
        }

        // Firstname
        if (Utils.isEmpty(this.regData.firstname) || this.regData.firstname.length < 3) {
            canSubmit             = false;
            this.errors.firstname = 'Prénom invalide';
        }

        // Address
        if (Utils.isEmpty(this.regData.locality) || this.regData.locality.length < 3) {
            canSubmit            = false;
            this.errors.locality = 'Veuillez saisir une localité valide';
        }

        // Password
        if (Utils.isEmpty(this.regData.password) || this.regData.password.length < 4) {
            canSubmit            = false;
            this.errors.password = 'Le mot de passe doit contenir au moins 4 caractères';
        }

        // Email
        this.regData.email = this.regData.email.toLowerCase();
        if (Utils.isEmpty(this.regData.email) || ! Utils.isValidEmail(this.regData.email)) {
            canSubmit         = false;
            this.errors.email = 'Veuillez saisir une addresse email correcte';
        }

        if (canSubmit) {
            // Terms
            if (this.termsAccepted !== true) {
                canSubmit         = false;
                this.errorMessage = 'Veuillez accepter les termes et conditions !';
            }
        }

        console.log('canSubmit', canSubmit);
        return canSubmit;
    }
}
