import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { ViewHelperService } from './services/view-helper.service';
import { EventManager } from '../library/event-manager';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { HeaderColor } from '@awesome-cordova-plugins/header-color/ngx';
import { DataService } from './services/data.service';
import { ProjectService } from './services/project.service';
import { NotificationService } from './services/notification.service';
import { UserAccountService } from './services/user-account.service';
import { AuthService } from './services/auth.service';
import { App } from '@capacitor/app';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    private execInterval1: any;
    private lastBackButtonPressedStamp: number;

    constructor(
        private platform: Platform,
        private headerColor: HeaderColor,
        private routeLocation: Location,
        private viewHelperService: ViewHelperService,
        private eventManager: EventManager,
        private navController: NavController,
        private dataService: DataService,
        private authService: AuthService,
        private userAccountService: UserAccountService,
        private notificationService: NotificationService,
        private projectService: ProjectService,
    ) {
        this.initializeApp();
    }

    ngOnInit() {
        // const path = window.location.pathname;
        console.log(window.location.pathname);
    }

    initializeApp() {
        this.platform.ready().then(() => {
            // this.statusBar.styleDefault();
            StatusBar.getInfo().then((info) => console.log(info)).catch((error) => console.warn(error));
            StatusBar.setStyle({ style: Style.Light }).then().catch((error) => console.warn(error));
            StatusBar.setBackgroundColor({ color: '#e5e6ea' })
                .then(() => StatusBar.getInfo().then((info) => console.log(info)))
                .catch((error) => console.warn(error));

            SplashScreen.hide().then().catch((error) => console.warn(error));

            this.headerColor.tint('#313A89').catch((error) => console.warn(error));

            this.startupOperations();

            this.trackBackButtonActions();
        });
    }

    private startupOperations() {
        // this.loadData();
        this.manageGlobalEvents();

        // this.authService.isLoggedIn().then(() => {
        //     console.log('USER IS LOGGED IN');
        //     // this.executePeriodicOperations();
        // });
    }

    private trackBackButtonActions() {
        this.platform.backButton.subscribeWithPriority(-1, () => {
            console.log('AT PATH :', this.routeLocation.path(), this.routeLocation.isCurrentPathEqualTo('/secure/tabs/home'));

            if (this.routeLocation.isCurrentPathEqualTo('/secure/tabs/home') || this.routeLocation.isCurrentPathEqualTo('root')) {
                console.log('SHOULD MIINMIZE APP');
                this.minimizeAppAfterDoubleBack();
            }
            if (! this.routeLocation.isCurrentPathEqualTo('/secure/tabs/home')) {
                this.navController.navigateRoot('/secure/tabs/home');
            }
        });
    }

    private minimizeAppAfterDoubleBack() {
        if ((Date.now() - this.lastBackButtonPressedStamp) < 800) {
            App.minimizeApp().catch((error) => console.error(error));
        } else {
            this.lastBackButtonPressedStamp = Date.now();
            this.viewHelperService.showToast(
                'Appuyez encore une fois pour quitter l\'app...',
                600,
                null,
                null,
                null,
                'opacity-09',
                'bottom'
            ).then().catch();
        }
    }

    private manageGlobalEvents() {
        this.eventManager.subscribe('UserIsAuthenticated', () => {
            this.schedulePeriodicOperations();
        });

        this.eventManager.subscribe('AuthFailedWhenFetchingData', () => {
            console.log('event AuthFailedWhenFetchingData catched');
            this.authService.signOut().catch((error) => console.error(error));
        });

        this.eventManager.subscribe('UserLoggedOut', () => {
            if (this.execInterval1) {
                clearInterval(this.execInterval1);
                this.execInterval1 = null;
            }

            // if (this.execInterval2) {
            //     clearInterval(this.execInterval2);
            //     this.execInterval2 = null;
            // }

            this.navController.navigateRoot('/login').then(() => {
                this.viewHelperService.showLoader('Veuillez patienter un instant').then(() => {
                    setTimeout(() => window.location.reload(), 1500);
                });
            });
        });

        this.eventManager.subscribe('PrimaryDataAvailable', (allData) => {
            console.log('catched PrimaryDataAvailable');
            this.userAccountService.storeUserData(allData);
            this.projectService.storeProjectsData(allData);
            this.notificationService.storeNotificationsData(allData);
        });

        this.eventManager.subscribe('SecondaryDataAvailable', (allData) => {
            // this.productService.storeProductsData(allData);
            // this.bonusService.storeBonusData(allData);
            // this.commissionService.storeCommissionsData(allData);
            // this.affiliationService.storeAffiliationData(allData);
        });
    }

    private schedulePeriodicOperations() {
        if (this.execInterval1) {
            console.warn('PeriodicOperations already');
            return;
        }

        this.execInterval1 = setInterval(this.updatePrimaryDataFromOnline.bind(this), 60000);
        setTimeout(this.updatePrimaryDataFromOnline.bind(this), 1000);

        // this.execInterval2 = setInterval(this.updateSecondaryDataFromOnline.bind(this), 180000);
        // setTimeout(this.updateSecondaryDataFromOnline.bind(this), 2000);
    }

    private updatePrimaryDataFromOnline() {
        console.log('executing updatePrimaryDataFromOnline()');
        this.dataService.getAndStoreData().then(() => {
            // this.helperService.showToast('Données mises à jour avec succès !', 2000, null,  'success', 'OK').then();
        }).catch(() => {
        });
    }
}
