import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountPage } from '../account/account.page';
import { TabsPage } from './tabs.page';

const routes: Routes = [
    {
        path: 'tabs',
        component: TabsPage,
        children: [
            {
                path: 'home',
                loadChildren: () => import('../home/home.module').then(m => m.HomePageModule)
            },
            {
                path: 'projects',
                loadChildren: () => import('../projects/projects.module').then(m => m.ProjectsPageModule)
            },
            {
                path: 'activities',
                loadChildren: () => import('../activities/activities.module').then(m => m.ActivitiesPageModule)
            },
            {
                path: 'account',
                component: AccountPage
                // loadChildren: () => import('../account/account.module').then(m => m.AccountPageModule)
            },
            {
                path: 'publications',
                loadChildren: () => import('../publications/publications.module').then(m => m.PublicationsPageModule)
            },
            {
                path: '',
                redirectTo: '/secure/tabs/home',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'publications',
        loadChildren: () => import('../publications/publications.module').then(m => m.PublicationsPageModule)
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class TabsPageRoutingModule {
}
