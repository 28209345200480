import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-draft-project-field',
    templateUrl: './draft-project-field.component.html',
    styleUrls: ['./draft-project-field.component.scss'],
})
export class DraftProjectFieldComponent implements OnInit {

    @Input() field: {
        name: string;
        label: string;
        type: string;
        value: any;
        required: boolean;
        error: any;
        placeholder?: any;
    };

    constructor() { }

    ngOnInit() { }

    fieldHasChanged(field) {
        console.log('fieldHasChanged', field);
        field.error = '';
    }
}
