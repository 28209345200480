import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RootPage } from './pages/root/root.page';
import { AuthGuard } from './guards/auth.guard';
import { LoginPage } from './pages/login/login.page';
import { WalkthroughPage } from './pages/walkthrough/walkthrough.page';
import { FaqPage } from './pages/faq/faq.page';

const routes: Routes = [
    {
        path: '',
        component: RootPage,
    },
    {
        path: 'walkthrough',
        component: WalkthroughPage,
    },
    {
        path: 'login',
        component: LoginPage,
        // loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'registration',
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule)
    },
    {
        path: 'faq',
        component: FaqPage
    },
    {
        path: 'home',
        redirectTo: '/secure/tabs',
        pathMatch: 'full'
    },
    {
        path: 'secure',
        canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
            },
            {
                path: 'projects/create',
                loadChildren: () => import('./pages/project-creation/project-creation.module').then(m => m.ProjectCreationPageModule)
            },
            {
                path: 'projects/:id',
                loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectPageModule)
            },
            {
                path: 'projects/:id/update',
                loadChildren: () => import('./pages/project-update/project-update.module').then(m => m.ProjectUpdatePageModule)
            },
            // {
            //     path: 'account/projects',
            //     loadChildren: () => import('./pages/account-projects/account-projects.module').then(m => m.AccountProjectsPageModule)
            // },
            {
                path: 'notifications',
                loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
            },
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
