/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { Model } from './model';
import { dateTool, Utils } from '../../library/utils';
import { SERVER_URL } from '../../environments/environment';

export class UserModel extends Model {

    public id: number;
    public firstname: string;
    public lastname: string;
    public phone: string;
    public email: string;
    public account_id;

    public fullname: string;
    public firstFirstname: string;
    public password: string;

    constructor(data) {
        super(data);

        // COMPUTED PROPERTIES
        this.fullname       = this.lastname + ' ' + this.firstname;
        this.firstFirstname = this.firstname?.split(' ')[0] ?? '';

        // this.avatarUrl = SERVER_URL + 'users/' + this.id + '/avatar';
        // this.avatarUrlStamped = this.avatarUrl + '?v=' + this.avatarStamp;
    }
}
