/* eslint-disable @typescript-eslint/dot-notation,@typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserModel } from '../../entities/user.model';
import { StorageService } from '../../services/storage.service';
import { DataService } from '../../services/data.service';
import { Utils } from '../../../library/utils';
import { UserAccountService } from '../../services/user-account.service';
import { ViewHelperService } from '../../services/view-helper.service';
import { AccountHead } from 'src/app/entities/account.model';
import { isObject } from 'rxjs/internal-compatibility';

@Component({
    selector: 'app-modal-update-account-head',
    templateUrl: './update-account-head.modal.html',
    styleUrls: ['./update-account-head.modal.scss'],
})

export class UpdateAccountHeadModal implements OnInit {

    @Input() head = null;

    headDraft = new AccountHead();
    isUpdate = false;

    errors: Record<string, string> = {};

    success: Record<string, any> = {};

    constructor(
        private modalController: ModalController,
        private viewHelperService: ViewHelperService,
        private dataService: DataService,
        private userAccountService: UserAccountService
    ) {
    }

    ngOnInit() {
        this.headDraft = new AccountHead(this.head);
        this.isUpdate = this.headDraft.id;
    }

    close() {
        const data = {};
        this.modalController.dismiss(data).then();
    }

    submitHead() {
        console.log(this.headDraft);

        if (!this.canSubmitHead()) {
            // this.viewHelperService.showToast(this.errorMessage, 3000).then();
            return;
        }

        this.viewHelperService.showLoader('Opération en cours ...').then(() => {
            this.userAccountService.createOrUpdateHead(this.headDraft)
                .then((result) => {
                    console.log(result);
                    this.viewHelperService.showToast('Effectué ! Cet élément a été bien ' + (this.isUpdate ? 'mis à jour.' : 'ajouté.'), 3000, '', 'success');
                    this.continue();
                })
                .catch((error) => {
                    this.handleErrorFeedback(error);
                })
                .finally(() => {
                    this.viewHelperService.hideLoader();
                });
        });

    }

    continue() {
        this.viewHelperService.hideLoader();
        this.modalController.dismiss();
    }

    private canSubmitHead() {
        let canSubmit = true;
        this.errors = {};

        // Phone
        this.headDraft.phone = Utils.sanitizePhone(this.headDraft.phone);
        if (this.headDraft.phone.length !== 8) {
            canSubmit = false;
            this.errors.phone = this.headDraft.phone.length === 0 ? 'Veuillez saisir votre téléphone' : 'Numéro invalide';
        }

        // Name
        if (Utils.isEmpty(this.headDraft.lastname) || this.headDraft.firstname.length < 2) {
            canSubmit = false;
            this.errors.lastname = 'Nom invalide';
        }

        // Firstname
        if (Utils.isEmpty(this.headDraft.firstname) || this.headDraft.firstname.length < 3) {
            canSubmit = false;
            this.errors.firstname = 'Prénom invalide';
        }

        // Address
        if (Utils.isEmpty(this.headDraft.position)) {
            canSubmit = false;
            this.errors.position = 'Poste ou fonction invalide';
        }

        console.log('canSubmit', canSubmit);
        return canSubmit;
    }

    private handleErrorFeedback(error) {
        console.log('Error', error);
        // this.viewHelperService.showToast(error.message);
        if (error.status === 'fail') {
            console.log(1);
            let errorInfos = '<br>';

            if (isObject(error.data)) {
                for (const key of Object.keys(error.data)) {
                    // errorInfos += '<br><span class="text-color-danger">◆ ' + error.data[key][0] + '</span>';
                    errorInfos += '◆ ' + error.data[key][0] + ' ; ';
                }
            }
            console.log(11);

            if (error.error_code === 'Validation Failed') {
                this.viewHelperService.showToast('Informations soumises sont invalides. ' + errorInfos, null, 'Données incorrectes', 'warning').then();
            } else {
                this.viewHelperService.showToast('Erreur lors de l\'opération. Veuillez revérifier les données soumises. ' + errorInfos, null, 'Désolé', 'danger').then();
            }
        } else {
            console.log(2);
            this.viewHelperService.showToast('Nous ne pouvons pas joindre le serveur. Veuillez vérifier votre connectivité à internet et réessayer', null, 'Connexion impossible', 'danger').then();
        }
    }

}
