import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService, private navController: NavController) {
        console.warn('AuthGuard');
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree> {
        return new Promise<boolean>((resolve => {
            this.authService.isLoggedIn().then((isLogged) => {
                console.warn('Is authenticated ?', isLogged);
                resolve(true);
            }).catch(() => {
                this.navController.navigateRoot('login').then(() => console.warn('Not authenticated ! Navigated to login page'));
                resolve(false);
            });
        }));
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree> {
        return this.canActivate(childRoute, state);
    }
}
