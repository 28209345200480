import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { ContentModel } from '../../entities/content.model';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.page.html',
    styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {

    public faq: ContentModel|null = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private contentService: ContentService
    ) {
    }

    ngOnInit() {
        console.log(this.activatedRoute.snapshot.queryParamMap.get('q'));

        this.contentService.getFAQ().then((faq) => {
            this.faq = faq;
        }).catch((err) => console.warn(err));
    }
}
