/* eslint-disable @typescript-eslint/naming-convention */

export const ORG_TYPES = {
    1: 'Coopérative simplifiée',
    2: 'Coopérative avec Conseil d\'Admin.'
};

export const TEXTS = {
    bondcash: {
        body: '<b>Bond Cash Action</b> est une première caisse d\'épargne région en Afrique qui assure et garantit la sécurité des investisseurs avec une rentabilité inégalée de leurs placements d\'une part, et apporte aux coopératives agricoles et minières les solutions idoines et adaptées à leurs problèmes pour la réalisation de leur projets d\'autre part.'
    },
    howItWorks: {
        1: {
            title: 'Créez votre compte',
            body: 'Créez un compte pour votre structure (coopérative) et accédez au tableau de bord de l\'application. Il vous sera attribué un numéro matricule automatiquement. Ensuite complètez votre compte avec les coordonnées, responsables et autres informations nécessaires. Une fois votre compte complété, vous pouvez passer à l\'étape suivante ...'
        },
        2: {
            title: 'Soumettez vos projets',
            body: 'Ajoutez vos projets agricoles, en suivant le formulaire préparé à cet effet. En plus des données clés à renseigner, vous devrez nécessairement joindre un document de <b>Plan d\'Affaires</b> pour chaque projet. Un caneva/exemplaire est disponible pour vous guider.'
        },
        3: {
            title: 'Obtenez vos financements',
            body: 'Après études concluantes et ajustements eventuels, vous bénéficierez des fonds et investissements nécessaires pour vos projets. Nous pouvons également vous accompagner dans l\'exécution de ces projets, et la distribution/vente des produits obtenus.'
        }
    }
};

export const PRODUCTS = [
    {
        id: 1,
        label: 'soja',
        name: 'Soja',
        image: 'soja.jpg',
    },
    {
        id: 2,
        label: 'cajou-decortique',
        name: 'Cajou Décortiqué',
        image: 'cajou-decortique.jpg',
    },
    {
        id: 3,
        label: 'cajou-brut',
        name: 'Cajou Brut',
        image: 'cajou-brut.jpg',
    },
    {
        id: 4,
        label: 'arachide',
        name: 'Arachide',
        image: 'arachide.jpg',
    },
    {
        id: 5,
        label: 'mais-jaune',
        name: 'Mais Jaune',
        image: 'mais-jaune.jpg',
    },
    {
        id: 6,
        label: 'sesame',
        name: 'Sésame',
        image: 'sesame.jpg',
    },
    {
        id: 7,
        label: 'piment-vert',
        name: 'Piment Vert',
        image: 'piment-vert.jpg',
    },
    {
        id: 8,
        label: 'cacao',
        name: 'Cacao',
        image: 'cacao.jpg',
    },
];
