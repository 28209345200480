/* eslint-disable @typescript-eslint/dot-notation,@typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { dateTool, Utils } from '../../../library/utils';
import { UserAccountService } from '../../services/user-account.service';
import { ViewHelperService } from '../../services/view-helper.service';
import { AccountModel } from 'src/app/entities/account.model';
import { isObject } from 'rxjs/internal-compatibility';
import { ORG_TYPES } from 'src/shared/data';

@Component({
    selector: 'app-modal-account-details-update',
    templateUrl: './account-details-update.modal.html',
    styleUrls: ['./account-details-update.modal.scss'],
})

export class AccountDetailsUpdateModal implements OnInit {

    @Input() action = 'update';

    account = new AccountModel({});
    updateData = {
        denomination: null,
        organization_type: null,
        phone: null,
        email: null,
        address: null,
        locality: null,
        region: null,
        activity_domain: null,
        members_count: null,
        creation_date: null,
    };
    orgTypes = ORG_TYPES;

    errors: Record<string, string> = {};

    success: Record<string, any> = {};

    constructor(
        private modalController: ModalController,
        private viewHelperService: ViewHelperService,
        private userAccountService: UserAccountService
    ) {
    }

    ngOnInit() {
        this.userAccountService.getAccount().then((account) => {
            this.account = account;
            this.hydrateUpdateData(account);
        });
    }

    hydrateUpdateData(account: AccountModel) {
        console.log(account);

        this.updateData.denomination = account.denomination;
        this.updateData.organization_type = account.organization_type;
        this.updateData.email = account.email;
        this.updateData.phone = account.phone;
        this.updateData.address = account.address;
        this.updateData.locality = account.locality;
        this.updateData.region = account.region;
        this.updateData.activity_domain = account.profile.activity_domain;
        this.updateData.members_count = account.profile.members_count;
        this.updateData.creation_date = account.profile.creation_date;
    }

    close() {
        const data = {};
        this.modalController.dismiss(data).then();
    }

    submitUpdate() {
        console.log(this.updateData);

        if (!this.canSubmit()) {
            console.warn(this.errors);
            this.viewHelperService.showToast('Données incorrectes, veuillez rectifier.').then();
            return;
        }

        this.viewHelperService.showLoader('Opération en cours ...').then(() => {
            this.userAccountService.updateAccount(this.updateData)
                .then((result) => {
                    console.log(result);
                    this.viewHelperService.showToast('✅ Effectué ! Les informations de votre compte ont été mis à jour avec succès.', 3000, '', 'success');
                    this.continue();
                })
                .catch((error) => {
                    this.handleErrorFeedback(error);
                })
                .finally(() => {
                    this.viewHelperService.hideLoader();
                });
        });

    }

    continue() {
        this.viewHelperService.hideLoader();
        this.modalController.dismiss();
    }

    formatRawDate(raw) {
        return dateTool(raw).format('YYYY-MM-DD');
    }

    private canSubmit() {
        let canSubmit = true;
        this.errors = {};

        // Phone
        this.updateData.phone = Utils.sanitizePhone(this.updateData.phone);
        if (this.updateData.phone.length !== 8) {
            canSubmit = false;
            this.errors.phone = this.updateData.phone.length === 0 ? 'Veuillez saisir votre téléphone' : 'Numéro invalide';
        }

        // Name
        if (Utils.isEmpty(this.updateData.denomination) || this.updateData.denomination.length < 2) {
            canSubmit = false;
            this.errors.denomination = 'Nom de la structure invalide';
        }

        // Email
        if (Utils.isEmpty(this.updateData.email) || ! Utils.isValidEmail(this.updateData.email)) {
            canSubmit = false;
            this.errors.email = 'Adresse email invalide';
        }

        // Address
        if (Utils.isEmpty(this.updateData.address)) {
            canSubmit = false;
            this.errors.address = 'Adresse de localisation invalide';
        }

        // Locality
        if (Utils.isEmpty(this.updateData.locality)) {
            canSubmit = false;
            this.errors.locality = 'Saisissez la localité ou ville du siège';
        }

        console.log('canSubmit', canSubmit);
        return canSubmit;
    }

    private handleErrorFeedback(error) {
        console.log('Error', error);
        // this.viewHelperService.showToast(error.message);
        if (error.status === 'fail') {
            console.log(1);
            let errorInfos = '<br>';

            if (isObject(error.data)) {
                for (const key of Object.keys(error.data)) {
                    // errorInfos += '<br><span class="text-color-danger">◆ ' + error.data[key][0] + '</span>';
                    errorInfos += '◆ ' + error.data[key][0] + ' ; ';
                }
            }
            console.log(11);

            if (error.error_code === 'Validation Failed') {
                this.viewHelperService.showToast('Informations soumises sont invalides. ' + errorInfos, null, 'Données incorrectes', 'warning').then();
            } else {
                this.viewHelperService.showToast('Erreur lors de l\'opération. Veuillez revérifier les données soumises. ' + errorInfos, null, 'Désolé', 'danger').then();
            }
        } else {
            console.log(2);
            this.viewHelperService.showToast('Nous ne pouvons pas joindre le serveur. Veuillez vérifier votre connectivité à internet et réessayer', null, 'Connexion impossible', 'danger').then();
        }
    }

}
