/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { Model } from './model';

export class ContentModel extends Model {

    id: number;
    key: string;
    title: string;
    body: string;
    created_at?: string;

    constructor(data = null) {
        super(data);
    }
}
