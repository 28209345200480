import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { DashboardTileComponent } from './dashboard-tile/dashboard-tile.component';
import { CardComponent } from './card/card.component';
import { DraftProjectFieldComponent } from './draft-project-field/draft-project-field.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';


@NgModule({
    declarations: [
        ProfilePhotoComponent,
        DashboardTileComponent,
        CardComponent,
        DraftProjectFieldComponent,
        ProductSelectionComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
    ],
    exports: [
        ProfilePhotoComponent,
        DashboardTileComponent,
        CardComponent,
        DraftProjectFieldComponent,
        ProductSelectionComponent
    ]
})

export class ComponentsModule {
}
