import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AccountDetailsUpdateModal } from './account-details-update.modal';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
    ],
    declarations: [AccountDetailsUpdateModal],
    entryComponents: [AccountDetailsUpdateModal]
})
export class AccountDetailsUpdateModalModule {
}
