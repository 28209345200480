import { Utils } from 'src/library/utils';
import { ProjectModel } from './project.model';
import _ from 'underscore';

export class ProjectDraft {
    title = '';
    projectId = null;

    businessPlan = {
        name: 'business_plan',
        value: null,
        filename: ''
    };

    part1 = {
        status: 'incomplete',
        fields: [
            {
                name: 'title',
                label: 'Libellé du projet',
                type: 'text',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'description',
                label: 'Description sommaire',
                type: 'longtext',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'total_duration',
                label: 'Durée totale du projet',
                type: 'number',
                value: undefined,
                required: true,
                error: '',
                placeholder: '(en mois)'
            },
        ],
        image: {
            name: 'image',
            value: null
        },
        products: []
    };

    part2 = {
        status: 'incomplete',
        fields: [
            {
                name: 'main_goal',
                label: 'But principal du projet',
                type: 'text',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'location',
                label: 'Lieu d’implantation',
                type: 'text',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'total_cost',
                label: 'Coût total du projet',
                type: 'number',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'investment_amount',
                label: 'Montant de l’investissement',
                type: 'number',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'capital_requirement_amount',
                label: 'Besoins en fonds de roulement ',
                type: 'number',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'self_contribution',
                label: 'Apport personnel',
                type: 'number',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'required_contribution',
                label: 'Montant Sollicité',
                type: 'number',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'sales_amount',
                label: 'Chiffres d’affaires',
                type: 'number',
                value: undefined,
                required: true,
                error: ''
            },
            {
                name: 'profitability',
                label: 'Rentabilité financière',
                type: 'number',
                value: undefined,
                required: true,
                error: '',
                placeholder: '(en %) exemple : 15'
            },
            {
                name: 'added_value',
                label: 'Valeur ajoutée',
                type: 'text',
                value: undefined,
                required: true,
                error: ''
            },
        ]
    };


    constructor(raw = null) {
        console.log('Raw data', raw);

        if (Utils.isNotEmpty(raw?.title)) {
            this.title = raw.title;
        }
        if (Utils.isNotEmpty(raw?.part1)) {
            this.part1 = raw.part1;
        }
        if (Utils.isNotEmpty(raw?.part2)) {
            this.part2 = raw.part2;
        }
        if (Utils.isNotEmpty(raw?.businessPlan)) {
            this.businessPlan = raw.businessPlan;
        }
    }

    getInputs() {
        const inputs: Record<any, any> = {};

        this.part1.fields.forEach((field) => {
            inputs[field.name] = field.value;
        });

        this.part2.fields.forEach((field) => {
            inputs[field.name] = field.value;
        });

        inputs[this.businessPlan.name] = this.businessPlan.value;
        inputs[this.part1.image.name] = this.part1.image.value;

        inputs.products = _.pluck(this.part1.products, 'id');

        if (this.projectId) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            inputs['id'] = this.projectId;
        }

        return inputs;
    }

    fromProject(project: ProjectModel): ProjectDraft {
        this.title = project.title;
        this.projectId = project.id;

        this.part1.fields.map((field) => {
            field.value = project[field.name];
        });
        this.part2.fields.map((field) => {
            field.value = project[field.name] ?? project.summary[field.name];
        });

        this.part1.products = project.products ?? [];

        return this;
    }
}
