import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './root.page.html',
    styleUrls: ['./root.page.scss'],
})
export class RootPage implements OnInit {

    newStartup = false;

    constructor(
        private navController: NavController,
        private menuController: MenuController,
        // private storageService: StorageService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        console.log('RootPage Init');

        this.menuController.enable(false).then();

        this.authService.isLoggedIn()
            .then(() => {
                console.log('OK ! authenticated');
                this.navController.navigateRoot('/secure/tabs/home').catch();
            })
            .catch(() => {
                console.log('AppStatus', this.authService.appStatus);

                if (this.authService.appStatus === 'awaitingConfirmation') {
                    this.navController.navigateRoot('/registration/confirmation').catch();
                    return;
                } else {
                    console.warn('Not authenticated');
                    this.newStartup = true;
                }
            });
        // this.authService.isLoggedIn().then(status => {
        //   if (status === true) {
        //     console.log('OK ! authenticated');
        //     this.navController.navigateRoot('/home').catch();
        //   } else {
        //     console.warn('Not authenticated');
        //     this.navController.navigateRoot('/startup').catch();
        //   }
        // });
    }

    continue() {
        this.navController.navigateRoot('/walkthrough').catch();
    }

}
