import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RootPageModule } from './pages/root/root.module';
import { HeaderColor } from '@awesome-cordova-plugins/header-color/ngx';
import { ComponentsModule } from './components/components.module';
import { TabsPageModule } from './pages/tabs/tabs.module';
import { LoginPageModule } from './pages/login/login.module';
import { RegistrationPageModule } from './pages/registration/registration.module';
import { FaqPageModule } from './pages/faq/faq.module';
import { WalkthroughPageModule } from './pages/walkthrough/walkthrough.module';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'bondcash_db',
        }),
        HttpClientModule,
        AppRoutingModule,
        RootPageModule,
        TabsPageModule,
        LoginPageModule,
        RegistrationPageModule,
        FaqPageModule,
        WalkthroughPageModule,
        ComponentsModule,
    ],
    providers: [
        HeaderColor,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
