import { Component } from '@angular/core';

@Component({
    selector: 'app-tabs',
    templateUrl: 'tabs.page.html',
    styleUrls: ['tabs.page.scss']
})
export class TabsPage {
    selectedTab;

    constructor() {
    }

    setCurrentTab(e) {
        this.selectedTab = e.tab;
        console.log(e);
    }

    iconVariant(tab: string) {
        return this.selectedTab !== tab ? '-outline' : '';
    }
}
