import { Injectable } from '@angular/core';
import { Utils } from '../../library/utils';
import { ViewHelperService } from './view-helper.service';
import { DataService } from './data.service';
import { ApiService } from './api.service';
import { NotificationModel } from '../entities/notification.model';
import { EventManager } from '../../library/event-manager';
import { LocalNotifications } from '@capacitor/local-notifications';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    latestUnreadId: number = null;
    isInitialized          = false;

    unreads: Array<NotificationModel>       = [];
    notifications: Array<NotificationModel>|null = null;

    triggeredNotificationIds: Array<number> = [];
    private channelGeneralExists: boolean;

    constructor(
        private helperService: ViewHelperService,
        private dataService: DataService,
        private apiService: ApiService,
        private eventManager: EventManager,
    ) {
        eventManager.subscribe('UserLoggedOut', () => {
            this.reset();
        });
    }

    loadNotifications() {
        console.log('loadNotifications');

        return new Promise<NotificationModel[]>((resolve, reject) => {
            this.dataService.getAnyData('notifications').then((notifications) => {
                console.log(notifications);
                this.processNotifications(notifications);

                console.log(this.notifications, this.unreads.length);

                resolve(this.notifications);

                this.triggerLocalNotifications().then();
            }).catch(() => {
                console.log('can not load notifications');
                reject('Notifications not found');
            });
        });
    }

    getLastNotifications(n = 5) {
        console.log('getLastNotifications');

        return new Promise<NotificationModel[]>((resolve, reject) => {
            if (this.notifications === null) {
                this.loadNotifications().then(() => {
                    resolve(this.notifications.slice(0, n));
                }).catch(() => {
                    resolve([]);
                });
            } else {
                resolve(this.notifications.slice(0, n));
            }
        });
    }

    markAllAsRead() {
        console.log('markAllAsRead');

        return new Promise((resolve, reject) => {
            this.clearAllLocalNotifications().then(() => {
                console.log(this.latestUnreadId, this.unreads.length);

                if (this.unreads.length > 0) {
                    this.apiService.makeOperationRequest('notifications/mark-read', { last: this.latestUnreadId })
                        .then((data) => {
                            console.log(data);
                            if (! Utils.isEmpty(data.notifications)) {
                                this.dataService.storeAnyData('notifications', data.notifications).then();
                            }
                            this.processNotifications(data.notifications);
                            resolve(true);
                        })
                        .catch(error => {
                            console.log(error);
                            reject(error);
                        });
                } else {
                    console.log('noting to do');
                }
            });
        });
    }

    clearAllLocalNotifications() {
        return new Promise(resolve => {
            if (! this.helperService.isCapacitor()) {
                resolve(true);
                return;
            }
            LocalNotifications.getPending().then((list) => {
                if (list.notifications.length > 0) {
                    LocalNotifications.cancel(list).finally(() => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            });
        });
    }

    storeNotificationsData(allData) {
        if (allData.notifications) {
            this.dataService.storeAnyData('notifications', allData.notifications, true, { cache: true, notify: false }).then(() => {
                this.processNotifications(allData.notifications);
                this.triggerLocalNotifications().then();
                this.eventManager.publish('NotificationsUpdated', this.notifications);
            });
        }
    }

    reset() {
        this.latestUnreadId = null;
        this.isInitialized  = false;
        this.unreads        = [];
        this.notifications  = [];

        this.clearAllLocalNotifications().then(() => this.triggeredNotificationIds = []);
    }

    private processNotifications(notifications) {
        console.log('processNotifications');
        this.isInitialized = true;
        this.unreads       = [];

        this.notifications = notifications.map((notification) => {
            if (notification.read_at == null) {
                this.unreads.push(notification);
                if (notification.id > this.latestUnreadId) {
                    this.latestUnreadId = notification.id;
                }
            }

            return new NotificationModel(notification);
        });
    }

    private async triggerLocalNotifications() {
        console.log('@triggerLocalNotifications');
        if (this.helperService.isCapacitor()) {
            if (! this.channelGeneralExists) {
                await LocalNotifications.listChannels().then((list) => {
                    if (list.channels.find((channel) => channel.id === 'general')) {
                        console.log('CHANNEL EXISTS');
                        this.channelGeneralExists = true;
                    } else {
                        LocalNotifications.createChannel({
                            id: 'general',
                            name: 'General',
                            description: 'Notifications génériques, sans spécificité',
                            importance: 3,
                            lights: true,
                            lightColor: 'green',
                            vibration: true,
                        }).then(() => {
                            console.log('CHANNEL CREATED');
                            this.channelGeneralExists = true;
                        });
                    }
                });
            }

            if (this.unreads.length > 0) {
                console.log('unreads exists');
                const notifications = [];

                this.unreads.forEach((notification) => {
                    if (this.triggeredNotificationIds.includes(notification.id)) {
                        console.warn('Notification already pushed', notification.id);
                    } else {
                        notifications.push({
                            title: notification.title,
                            body: notification.message,
                            largeBody: notification.message,
                            id: notification.id,
                            schedule: { at: new Date(Date.now() + 1000) },
                            channelId: 'general'
                        });
                        this.triggeredNotificationIds.push(notification.id);
                        console.log(this.triggeredNotificationIds);
                    }
                });

                // push local notifications
                const notifs = await LocalNotifications.schedule({ notifications });
                console.log('scheduled notifications', notifs);
            }
        }
    }
}
