import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ApiService } from './api.service';
import { Utils } from '../../library/utils';
import { ContentModel } from '../entities/content.model';
import { ViewHelperService } from './view-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    private faq: ContentModel = null;

    constructor(
        private dataService: DataService,
        private apiService: ApiService,
        private helperService: ViewHelperService,
    ) {
    }

    getFAQ() {
        return new Promise<ContentModel>((resolve, reject) => {
            if (Utils.isNotEmpty(this.faq)) {
                resolve(this.faq);
                return;
            }

            this.dataService.getAnyData('contentsFAQ').then((content) => {
                console.log(content);
                this.setFAQ(content);
                resolve(this.faq);
            }).catch((error) => {
                console.log('nothing found ; getting from server', error);
            });

            this.helperService.showLoader('Actualisation ...').then(() => {
                this.apiService.makeOperationRequest('contents/content?key=FAQ').then((data) => {
                    this.dataService.storeAnyData('contentsFAQ', this.faq).then();
                    this.setFAQ(data.content);
                    resolve(this.faq);
                }).catch((apiError) => {
                    console.warn('No content found for FAQ', apiError);
                    reject(false);
                }).finally(() => {
                    this.helperService.hideLoader();
                });
            });
        });
    }

    setFAQ(content) {
        this.faq      = new ContentModel(content);
        this.faq.body = JSON.parse(this.faq.body);
        console.log(this.faq);
    }
}
