import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {
    @Input() bgColor  = 'white';
    @Input() boxClass = null;

    @Input() shadow: 'normal'|'large'|'small'|'none' = 'normal';

    @Input() title      = null;
    @Input() titleClass = null;
    @Input() titleColor = null;
    @Input() titleIcon  = null;

    @Input() bodyClass = null;

    constructor() {
    }
}
