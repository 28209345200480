/* eslint-disable @typescript-eslint/naming-convention */
import { Utils } from '../../library/utils';

export class Model {

    id: number;
    created_at?: string|null;
    updated_at?: string|null;

    public constructor(data: Record<string, any> = null) {
        if (!Utils.isEmpty(data)) {
            Object.assign(this, data);
            // eslint-disable-next-line guard-for-in
            // for (const key in data) {
            //   // noinspection JSUnfilteredForInLoop
            //   this[key] = data[key];
            // }
        }
    }
}
