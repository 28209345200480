/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { DataService } from './data.service';
import { UserModel } from '../entities/user.model';
// import { AccountModel } from '../entities/account.model';
import { EventManager } from '../../library/event-manager';
import { Utils } from '../../library/utils';
import { AccountModel } from '../entities/account.model';

@Injectable({
    providedIn: 'root'
})
export class UserAccountService {

    public avatarImgCache: Record<string, any> = {};

    private user: UserModel = null;
    private account: AccountModel = null;

    constructor(
        private apiService: ApiService,
        private dataService: DataService,
        private eventManager: EventManager
    ) {
        // eventManager.subscribe('UserUpdated', user => {
        //   console.log('UserAccountService received event UserUpdated', user);
        //   this.user = user;
        // });
        eventManager.subscribe('UserLoggedOut', () => {
            this.reset();
        });
    }

    updateUserInfos(inputs) {
        console.log('updateUserInfos');
        return this.apiService.makeOperationRequest('user/update-infos', inputs);
    }

    updateUserPassword(oldPassword, newPassword) {
        console.log('updateUserPassword');
        // noinspection JSUnusedGlobalSymbols
        return this.apiService.makeOperationRequest('user/update-password', {
            old_password: oldPassword,
            new_password: newPassword,
        });
    }

    createOrUpdateHead(headData) {
        return new Promise<boolean>((resolve, reject) => {
            const url = headData.id ? 'account/heads/update' : 'account/heads/create';

            this.apiService.makeOperationRequest(url, headData)
                .then((data) => {
                    this.saveRawAccount(data.account);
                    resolve(true);
                })
                .catch((err) => {
                    console.error(err);
                    reject('Failed');
                });;
        });
    }

    updateAccount(accountData) {
        return new Promise<boolean>((resolve, reject) => {
            this.apiService.makeOperationRequest('account/update', accountData)
                .then((data) => {
                    this.saveRawAccount(data.account);
                    resolve(true);
                })
                .catch((err) => {
                    console.error(err);
                    reject('Failed');
                });;
        });
    }

    async getUser(): Promise<UserModel> {
        return new Promise<UserModel>((resolve, reject) => {
            if (this.user != null) {
                console.log('user found in service');
                resolve(this.user);
            } else {
                // console.log('Not found in service');
                this.dataService.getAnyData('user').then(rawUser => {
                    console.log('User loaded from dataService');
                    const user = new UserModel(rawUser);
                    this.user = user;
                    resolve(user);
                }).catch(() => {
                    reject();
                });
            }
        });
    }

    async getAccount(): Promise<AccountModel> {
        return new Promise<AccountModel>((resolve, reject) => {
            if (this.account != null) {
                // console.log('Account found in service');
                resolve(this.account);
            } else {
                this.dataService.getAnyData('account').then(rawAccount => {
                    // console.log('Account loaded from dataService');
                    const account = new AccountModel(rawAccount);
                    this.account = account;
                    resolve(account);
                }).catch(() => {
                    // TODO: remove this
                    resolve(new AccountModel({}));
                    // reject();
                });
            }
        });
    }

    async saveRawUser(rawUser): Promise<UserModel> {
        return new Promise<UserModel>((resolve, reject) => {
            this.dataService.storeAnyData('user', new UserModel(rawUser), true, { cache: true, notify: false })
                .then(() => {
                    this.user = new UserModel(rawUser);
                    this.eventManager.publish('UserUpdated', this.user);
                    resolve(this.user);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    async saveRawAccount(rawAccount): Promise<AccountModel> {
        return new Promise((resolve, reject) => {
            this.dataService.storeAnyData('account', rawAccount, true, { cache: true, notify: false })
                .then(() => {
                    this.account = new AccountModel(rawAccount);
                    this.eventManager.publish('AccountUpdated', this.account);
                    resolve(this.account);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    checkRequiredActions(user: UserModel = null) {
        const requiredActions = {};
        user = user ?? this.user;
        if (Utils.isEmpty(user.email)) {
            // requiredActions['addEmail'] = {
            //   label: 'addEmail',
            //   level: 'hight',
            //   color: 'danger'
            // };
        }

        return requiredActions;
    }

    storeUserData(allData: any) {
        if (allData.user) {
            this.saveRawUser(allData.user).then();
        }

        if (allData.account) {
            this.saveRawAccount(allData.account).then();
        }
    }

    // async loadUserAvatarImg(user: UserModel) {
    //     if (this.avatarImgCache[user.id] !== undefined) {
    //         return this.avatarImgCache[user.id];
    //     }
    //
    //     await this.apiService.get(user.avatarUrlStamped, {}, false, true).toPromise().then((res) => {
    //         console.log(res);
    //         this.avatarImgCache[user.id] = res;
    //         return true;
    //     }).catch((err) => {
    //         console.log('No avatar found', err);
    //         return false;
    //     });
    // }

    reset() {
        this.user = null;
        this.account = null;
    }
}
