import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ViewHelperService } from '../../services/view-helper.service';
import { AuthService } from '../../services/auth.service';
import { SERVER_URL } from '../../../environments/environment';
import { Utils } from '../../../library/utils';
import { Browser } from '@capacitor/browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

    username: string = null;
    password: string = null;

    errors: Record<string, any> = {};

    constructor(
        private navController: NavController,
        private viewHelperService: ViewHelperService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
    }

    submit() {
        // this.navController.navigateRoot('/home').then().catch((error) => console.warn(error));
        // return;

        if (this.canSubmit()) {
            this.viewHelperService.showLoader('Tentative d\'autentification ...').then(() => {
                this.authService.signIn(this.username, this.password).then(result => {
                    console.log('result', result);
                    this.navController.navigateRoot('/secure/tabs/home').then().catch((error) => console.warn(error));
                    // const navigationExtras: NavigationOptions = {
                    //   queryParams: {action: 'login', canCancel: false}
                    // };
                }).catch(error => {
                    console.log(error);
                    if (error.error_code === 'Validation Failed') {
                        this.viewHelperService.showAlert('Données incorrectes', 'Informations soumises sont invalides. Veuillez bien remplir le formulaire', 'Rectifier').then();
                    } else if (error.error_code === 'Unauthorized') {
                        this.viewHelperService.showAlert('Identifiants incorrects', 'Impossible de vous connecter avec cet identifiant et mot de passe', 'Réessayer').then();
                    } else if (error.error_code === 'Locked Account') {
                        this.viewHelperService.showAlert('Compte bloqué', 'Ce compte est bloqué ou désactivé. Veuillez contacter l\'administration').then();
                    } else {
                        this.viewHelperService.showAlert('Connexion impossible', 'Veuillez vérifier votre connectivité à internet et réessayer').then();
                    }
                }).finally(() => {
                    this.viewHelperService.hideLoader();
                });
            });
        } else {
            this.viewHelperService.showErrorToast('Veuillez remplir correctement les champs', 3000).then();
        }
    }

    launchPwdRecovery() {
        const url = SERVER_URL + 'user/pwd/request-recovery';
        Browser.open({ url }).then(() => console.log('Open url', url));
    }

    goToRegistration() {
        this.navController.navigateForward('/registration').then();
    }

    private canSubmit() {
        let canSubmit = true;
        this.errors   = {};

        // Username
        if (Utils.isEmpty(this.username)) {
            canSubmit            = false;
            this.errors.username = 'Veuillez saisir votre identifiant';
        }

        // Pass
        if (Utils.isEmpty(this.password)) {
            canSubmit            = false;
            this.errors.password = 'Veuillez saisir votre mot de passe';
        }

        console.log('canSubmit', canSubmit);
        return canSubmit;
    }

}
